import React, { FC } from 'react';
import { useAuthUser } from '@frontegg/react';
// import { withFrontegg } from "./hoc/withFrontegg";
// setup fake backend
import Layout from "./components/Layout";
// import { configureFakeBackend } from './helpers';
// Themes

// default
import "./assets/scss/theme.scss";

// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';


// configure fake backend
// configureFakeBackend();

/**
 * Main app component
 */

const App: FC = () => {
  const user  = useAuthUser();

  return (
    <div>
      { user && <Layout/>}
    </div>
  );
};

// export default withFrontegg(App);
export default App;
