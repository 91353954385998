// Modules
import React, { useEffect, useCallback } from "react";
import classnames from "classnames";
import ReactDOMServer from "react-dom/server";

// Assets
import ArrowRight from "../assets/images/purple-arrow-right-minimalize.svg";
import Puzzle from "../assets/images/puzzle.svg";

const getNode = (entity) => {
  const initStyle = "position: absolute; z-index: 1;";
  switch (entity) {
    case "audit":
      return {
        node: document.querySelector(".fe-audits-container .fe-page-header"),
        style: `${initStyle} left: 275px; top: 25px;`,
        link: 'https://frontegg.com/join?product=audit_logs'
      };
    case "team":
      return {
        node: document.querySelector(".users .fe-page-header"),
        style: `${initStyle} left: 250px; top: 25px;`,
        link: 'https://frontegg.com/join?product=team_and_policy'
      };
    case "webhooks":
      return {
        node: document.getElementsByClassName("webhooks-table")[0]?.firstChild,
        style: `${initStyle} left: 400px; top: 33px;`,
        link: 'https://frontegg.com/join'
      };
    case "profile":
      return {
        node: document.getElementsByClassName("profileHeader__title")[0],
        style: `${initStyle} left: 200px; top: -3px;`,
        link: 'https://frontegg.com/join'
      };
    case "sso":
      return {
        node: document.getElementsByClassName("fe-page-header")[0]?.firstChild,
        style: `${initStyle} left: 383px; top: 6px;`,
        link: 'https://frontegg.com/join?product=sso_and_saml'
    };
    case "reports":
      return {
        node: document.querySelector(".fe-reports .fe-page-header"),
        style: `${initStyle} left: 320px; top: 25px;`,
        link: 'https://frontegg.com/join?product=reports'
      };
    case 'slack':
      return {
        node: document.querySelector(".slack-integration__header"),
        style: `${initStyle} left: 15em; top: 25px;`,
        link: 'https://frontegg.com/join'
      }
    default:
      return false;
  }
};

function JoinBtn({ className, children, isDirectRender, entity, to }) {
  const button = (
    <button className={classnames("join-btn", className)} onClick={() => window.open(to || "https://frontegg.com/join")}>
      <img src={Puzzle} alt='puzzle'/>
      {children || "Plug in to Your application"}
      <img src={ArrowRight} alt='arrow'/>
    </button>
  );

  const renderButton = useCallback(() => {
    const id = `join-btn-container-${entity}`;
    const isAlreadyExist = !!document.getElementById(id);
    if (isAlreadyExist || !entity) return;
    const buttonData = getNode(entity);
    const el = buttonData?.node;
    const style = buttonData?.style;

    if (el) {
      el.style.position = "relative";
      const stringifiedJoinBtn = ReactDOMServer.renderToString(button);
      const div = document.createElement("div");
      div.id = id;
      div.innerHTML = stringifiedJoinBtn;
      div.style = style;
      div.onclick = () => window.open(buttonData?.link || "https://frontegg.com/join");
      el.insertBefore(div, el.lastChild);
    }
  }, [entity, button]);

  const watchDOMChange = useCallback(renderButton, [renderButton]);

  useEffect(() => {
    const root = document.getElementById("layout-container");
    const observer = new MutationObserver(watchDOMChange);
    const config = { attributes: true, childList: true, subtree: true };
    root && observer.observe(root, config);

    return () => observer.disconnect();
  }, [watchDOMChange]);

  return isDirectRender ? button : null;
}

export default JoinBtn;
