import React, { Component, Suspense } from 'react';
import CookieConsent from 'react-cookie-consent';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { isUserAuthenticated } from '../helpers/authUtils';
import * as layoutConstants from '../constants/layout';
import { allFlattenRoutes as routes } from '../routes';
import sendAudits from '../helpers/sendAudits';
import { ReactTourContext } from '../context/ReactTourContext';
import { SITE_NAME } from '../constants/general';

const AuthLayout = React.lazy(() => import('../layouts/Auth'));
const VerticalLayout = React.lazy(() => import('../layouts/Vertical'));
const HorizontalLayout = React.lazy(() => import('../layouts/Horizontal'));
const LOUDAPI_DISPLAYED = 'loudapi_displayed_onboarding';

const loading = () => <div/>;

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const HOC = class extends Component {

  state = {
    isTourOpen: false,
    stepIndex: 0,
    steps: [
      {
        target: '.logo',
        disableBeacon: true,
        content: <div>Welcome to the Frontegg demo environment, which imagines a SaaS platform called {SITE_NAME} and
          shows how it becomes enterprise-ready using Frontegg.</div>,
      },
      {
        target: '.side-nav-item:nth-of-type(7)',
        content: <div>Enterprises require transparency. {SITE_NAME} did not have an <b>Audit Logs</b> feature before,
          but building one using Frontegg took <b>only 4 hours</b>. Now their clients can monitor user activity in
          real-time.</div>,
        clickOnStep: true,
        clickTarget: '.side-nav-item:nth-of-type(7) a',
      },
      {
        target: '.side-nav-item:nth-of-type(8)',
        content: <div>Any SaaS security checklist will tell you that authorization is crucial. Using Frontegg’s <b>Team
          & Access Policies</b>, {SITE_NAME} can provide its customers with integrated user permissions and invites. <b>Integration
          time? Only 2 hours</b>.</div>,
        clickOnStep: true,
        clickTarget: '.side-nav-item:nth-of-type(8) a',
      },
      {
        target: '.frontegg .wrap_notifications',
        content: <div>Notifications are an important communication tool between platforms and their users. Whether it is
          an anomaly or a business event, you can use Frontegg’s <b>Notification Center</b>, which includes an in-app
          bell, web Push, webhooks, slack notifications and much more. <b>Integration takes only 4 hours</b>.</div>,
        targetOnNext: '.side-nav-item:nth-of-type(8) a',
        // clickOnStep: true,
        // clickTarget: '.frontegg .wrap_notifications .notifications__icon',
      },
      {
        target: '.side-nav-item:nth-of-type(9)',
        content: <div>Integrating a SaaS platform authentication with an identity provider for Single Sign-On doesn’t
          have to be complicated. By using <b>Frontegg’s fully-managed SSO</b>, {SITE_NAME} didn’t have to deal with
          complex SAML flows. The integration <b>took only 4 hours</b>.</div>,
        clickOnStep: true,
        clickTarget: '.side-nav-item:nth-of-type(9) a',
      },
      {
        target: 'body',
        placement: 'center',
        content: 'These are just some of the features Frontegg can take off your plate, enabling you to focus on your core business without sacrificing enterprise-readiness.',
        clickOnStep: true,
        disableBeacon: true,
      },

    ],
  };

  componentDidUpdate() {
    const { steps } = this.state;
    const bell = document.querySelector('.frontegg .wrap_notifications');
    if (!bell && steps.length === 6) {
      const newSteps = steps.slice(0, 3).concat(steps.slice(4));
      this.setState({ steps: newSteps });
    }
  }

  async componentDidMount() {
    // setTimeout(() => {
    //     if (localStorage.getItem(LOUDAPI_DISPLAYED) !== 'yes') {
    //         this.setState({ isTourOpen: true })
    //     }


    // }, 2000);

    sendAudits({ user: 'Johnny Lu', action: 'Accessed', severity: 'Info' });
  }

  /**
   * Returns the layout component based on different properties
   */
  getLayout = () => {
    if (!isUserAuthenticated()) return AuthLayout;

    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };


  handleJoyrideCallback = (data) => {
    const { action, index, type, status, step } = data;
    if (action === ACTIONS.UPDATE && this.state.isTourOpen && step && step.clickOnStep) {
      if (typeof (step.clickTarget) === 'string') {
        const item = document.querySelector(step.clickTarget);
        item.click();
      } else if (Array.isArray(step.clickTarget)) {
        for (const itemToClick of step.clickTarget) {
          const item = document.querySelector(itemToClick);
          item.click();
        }
      }
    }

    if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
      localStorage.setItem(LOUDAPI_DISPLAYED, 'yes');
      this.setState({ isTourOpen: false, stepIndex: 0 });
    } else if (action === ACTIONS.CLOSE && type === EVENTS.STEP_BEFORE) {
      const stepIndex = index;
      this.setState({ stepIndex, isTourOpen: false });
    } else if (type === EVENTS.STEP_AFTER) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.setState({ stepIndex });
    }
  };

  render() {
    const { isTourOpen, stepIndex } = this.state;
    const Layout = this.getLayout();
    const contextValue = {
      isTourOpen,
      openTour: () => this.setState({ isTourOpen: true, stepIndex: stepIndex ? stepIndex - 1 : 0 }),
    };
    return (
      <Suspense fallback={loading()}>
        <ReactTourContext.Provider value={contextValue}>
          <Layout {...this.props}>
            <Switch>
              {routes.filter(route => !route.children).map((route, index) => {
                return <route.route
                  key={index} path={route.path} roles={route.roles} exact={route.exact}
                  component={(props) => {
                    return <route.component {...props} />;
                  }}/>;
              })}
            </Switch>
            <Joyride
              locale={{
                last: 'Finish Guide',
              }}
              autoStart
              showProgress
              showSkipButton
              continuous
              beaconComponent={() => null}
              steps={this.state.steps}
              callback={this.handleJoyrideCallback}
              run={isTourOpen}
              stepIndex={this.state.stepIndex}>
            </Joyride>
            <CookieConsent>
              This website uses cookies to improve your experience. We'll assume you're ok with this, but you can
              opt-out if you wish.
              <a style={{ float: 'right', color: 'white' }} href="https://frontegg.com/privacy-policy" alt="frontegg-pp"
                 target="_blank" rel="noopener noreferrer"><span>Privacy Policy</span></a>
            </CookieConsent>
          </Layout>
        </ReactTourContext.Provider>
      </Suspense>
    );
  }
};


export default connect(state => ({ layout: state.Layout }))(HOC);
