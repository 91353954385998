export default async function sendDataToAudit(data) {
  const host = window.location.hostname === 'localhost' ? `${window.location.hostname}:8080` : window.location.hostname;

  try {
    await fetch(`${window.location.protocol}//${host}/audit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    })
  } catch (e) {
    console.error(e);
  }
}
