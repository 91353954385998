import React from "react";
import * as Frontegg from "@frontegg/react";

import sendDataToAudit from '../../helpers/sendAudits'
import { webhooksData } from '../utils/sendAuditsData';
// import { WebhookComponent } from '@frontegg/react-connectivity';

import JoinBtn from '../../components/JoinBtn';

class WebHooksPage extends React.Component {
  componentDidMount() {
    sendDataToAudit(webhooksData());
  }

  render() {
    return (
      <div style={{ paddingTop: '15px' }}>
        <JoinBtn entity='webhooks' />
        {/*<WebhookComponent />*/}
      </div>
    )
  }
}

export default WebHooksPage;
