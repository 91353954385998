import React, { useState } from 'react';

// assets
import FronteggIcon from '../assets/images/frontegg-logo.png';

function FronteggInfoIcon() {
  const [open, setOpen] = useState(false);

  const handleEnter = () => open || setOpen(true);
  const handleLeave = () => open && setOpen(false);

  return (
    <span onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
      <span className='frontegg-info-icon'>
        <img src={FronteggIcon} alt='frontegg' />
        <span>i</span>
      </span>
      {open && <div className='frontegg-tooltip'>
        This screen container an in-app component powered by Frontegg SaaS platform.
        <br />
        <br />
        This remark exists only in this demo and will not appear in your SaaS application
      </div>}
    </span>
  );
}

export default FronteggInfoIcon;
