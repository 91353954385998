export const ROUTES = Object.freeze({
  dashboard: '/dashboard',
  audits: '/audits',
  insights: '/insights',
  teamManagement: '/team_management',
  webhooks: '/webhook',
  services: '/services',
  api: '/api',
  settings: '/settings',
  sso: '/sso',
  settingsSso: '/settings/sso',
  account: '/account',
  profile: '/profile',
  notifications: '/notifications',
  reports: '/reports',
  accountLogin: '/account/login',
  accountLogout: '/account/logout',
  accountRegister: '/account/register',
  accountConfirm: '/account/confirm',
  accountForgot: '/account/forget-password',
});
