import { v4 as uuid } from 'uuid'

export const getRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const randomText = (v1, v2, v3 = v1) => {
  const number = Math.floor(Math.random() * 10);
  if (number < 4) {
    return v1;
  } else if (number >= 4 && number < 7) {
    return v2;
  } else {
    return v3;
  }
};

const randomBoolean = () => {
  return Math.random() >= 0.5;
};

const randomName = () => {
  const list = [
    "Ardelia Dismuke",
    "Kieth Mason",
    "Darcie Policastro",
    "Marg Lovelace",
    "Rena Flanders",
    "Clement Gallop",
    "Melda Richert",
    "Florine Pinion",
    "Verona Gonzalas",
    "Valery Krieg",
    "Debora Coddington",
    "Caitlin Hodes",
    "Sammie Fenster",
    "Normand Menz",
    "Herb Mcwain",
    "Geraldo Shupe",
    "Jennell Fant",
    "Wendi Burghardt",
    "Deanna Post",
    "Emelia Modeste",
    "Tillie Casias",
    "Lenard Chicoine",
    "Lenna Nodine",
    "Carolee Baldon",
    "Thomas Salser",
    "Rhoda Blaylock",
    "Gail Blackerby",
    "Iris Basso",
    "Kelvin Casella",
    "Naida Rinker"
  ];
  const index = Math.floor(Math.random() * list.length);

  return list[index]
}

export const dashboardData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    resource: "Dashboard",
    severity: "Info",
    calls: getRndInteger(1000, 10000),
    errors: getRndInteger(1, 100),
    securityAlerts: getRndInteger(1, 100),
    anomalies: getRndInteger(1, 100),
    uniqueUsers: getRndInteger(1000, 10000),
    uniqueAPIs: getRndInteger(1, 100),
    uniqueMicroservices: getRndInteger(1, 100),
  }
};

export const auditLogsData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    resource: "Audit",
    severity: "Info",
    totalRows: getRndInteger(1000, 10000),
    searchText: randomText("None", "Dashboard", "Error"),
    piiReturned: `${randomBoolean()}`,
  }
};

export const teamData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    severity: "Info",
    resource: "Teams",
    totalRows: getRndInteger(10, 100),
    searchText: randomText("None", "James", "Michael")
  }
}

export const webhooksData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    severity: "Info",
    resource: "Webhooks",
    totalRows: getRndInteger(10, 100),
    searchText: randomText("None", "Services", "Added")
  }
}

export const servicesData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    severity: "Info",
    resource: "Services",
    totalRows: getRndInteger(10, 100),
    searchText: randomText("None", "Audits", "Users"),
    source: randomText("Menu", "Email", "Direct Link")
  }
}

export const apisData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    severity: "Info",
    resource: "APIs",
    totalRows: getRndInteger(10, 100),
    searchText: randomText("None", "Audits", "Users"),
    source: randomText("Menu", "Email", "Direct Link")
  }
}

export const insightsData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    severity: "Info",
    resource: "Insights",
    totalRows: getRndInteger(10, 100),
    searchText: randomText("None", "Audits", "Users"),
    source: randomText("Menu", "Email", "Direct Link")
  }
}

export const ssoData = () => {
  return {
    user: randomName(),
    action: "Accessed",
    severity: "Info",
    resource: "SSO",
    totalRows: getRndInteger(10, 100),
    state: randomText("Clear", "Preconfigured", "Domain validation"),
    domainValidated: `${randomBoolean()}`,
    typeConfigured: randomText("SAML", "Okta", "Azure"),
  }
}

export const serviceScanStartData = () => {
  return {
    user: randomName(),
    action: "Manual Scan Started",
    severity: randomText("Attention", "Error"),
    resource: "Service",
    scanId: uuid(),
    service: randomText("Payments", "Cars", "Users"),
  }
}
