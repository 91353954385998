import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { FronteggProvider } from '@frontegg/react';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './redux/store';
import { BrowserRouter } from 'react-router-dom';

const contextOptions = {
  baseUrl: 'https://dana-sharir.frontegg.com'
}

ReactDOM.render(
  <FronteggProvider contextOptions={contextOptions}>
    <Provider store={configureStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </FronteggProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

